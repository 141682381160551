<template>
  <b-modal
    id="edit-exam-sidebar"
    centered
    :visible="isEditExamSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    size="sm"
    backdrop
    no-header
    right
    :ok-title="$t('ok')"
    ok-only
    :title="$t('updateExam')"
    @ok="onSubmit(blankExamData)"
    @hidden="formValidation(resetblankexam).resetForm"
    @change="(val) => changed(val)"
  >
    <validation-observer
      #default="{ handleSubmit }"
      ref="formValidation(
      resetblankexam
    ).refFormObserver"
    >
      <!-- Form -->

      <b-form
        ref="refForm"
        class="p-2 modal-form"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <button ref="submitButtonRef" type="submit" class="d-none" />
        <!-- ExamTopic -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="ExamTopic"
          class="w-100"
        >
          <b-form-group :label="$t('examName')" label-for="ExamName">
            <b-form-input
              id="eExamName"
              v-model="blankExamData.examName"
              autofocus
              :state="
                formValidation(resetblankexam).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditExamSidebarActive",
    event: "update:is-edit-exam-sidebar-active",
  },
  props: {
    isEditExamSidebarActive: {
      type: Boolean,
      required: true,
    },
    exam: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankExamData: { ...this.exam },
      imgStr: "",
      formValidation: formValidation,
      profileFile: null,
      picturechanged: false,
      refInputEl: null,
      previewEl: null,
      inputImageRenderer: null,
    };
  },
  mounted() {
    var { inputImageRenderer } = useInputImageRenderer(
      this.$refs.refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        this.picturechanged = true;
        this.$refs.previewEl.src = base64;
        this.imgStr = base64;
      }
    );
    this.inputImageRenderer = inputImageRenderer;
  },
  methods: {
    async changed(val) {
      this.imgStr = "";
      this.resetblankexam();
      this.$emit("update:is-edit-exam-sidebar-active", val);
    },
    onSubmit() {
      this.blankExamData.imgstr = this.imgStr;
      store
        .dispatch("definitionsModule/updateExam", this.blankExamData)
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-exam-sidebar-active", false);
        });
    },
    async resetblankexam() {
      this.blankExamData = { ...this.exam };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-exam-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
