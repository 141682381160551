<template>
  <div><exam-list /></div>
</template>

<script>
import ExamList from "./components/ExamList/ExamList.vue";
export default {
  components: { ExamList },
};
</script>

<style></style>
